<template>
  <v-autocomplete
    v-model="select"
    :loading="loading"
    :items="searchedCities"
    :label="label"
    :placeholder="placeholder"
    item-text="description"
    :hide-no-data="hideData"
    item-value="osm_id"
    :search-input.sync="search"
    cache-items
    clearable
    outlined
    dense
    @change="sendosmId"
  ></v-autocomplete>
</template>

<script>
import { ref, watch, getCurrentInstance } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { mapGetters } from 'vuex'
import store from '@/store'

export default {
  name: 'CityInput',
  emits: ['getCity'],

  props: {
    // eslint-disable-next-line vue/require-default-prop
    label: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    placeholder: {
      type: String,
    },
  },

  computed: {
    ...mapGetters('city', ['searchedCities']),
  },

  setup() {
    const loading = ref(false)
    const hideData = ref(false)
    const items = ref([])
    const search = ref(null)
    const select = ref(null)

    const querySelections = async query => {
      loading.value = true
      hideData.value = true
      await store.dispatch('city/searchCityWithMap', { city: query })
        .then(() => {
          loading.value = false
          hideData.value = false
        })
    }

    const instance = getCurrentInstance()

    const sendosmId = () => {
      instance.emit('getCity', select.value)
    }

    watch(search, query => {
      // eslint-disable-next-line no-unused-expressions
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        query && query !== select.value && querySelections(query)
      }, 500)
    })

    return {
      sendosmId,
      hideData,
      select,
      loading,
      items,
      search,
      querySelections,
      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style scoped>

</style>
