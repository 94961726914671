<template>
  <div>
    <custom-toolbar
      :title="$t('Soumisions')"
      :total="$store.getters['document/total']"
      @refresh="getDocuments"
    />
    <v-card class="mb-6 mt-6">
      <v-alert
        v-model="isAlertVisible"
        :color="error ? 'error' : 'success'"
        dismissible
        text
        transition="slide-y-transition"
      >
        {{ message }}
      </v-alert>
      <div class="m-2">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="$store.state.document.field.user"
              label="Filtrer par utilisateur"
              outlined
              dense
              placeholder="Filtrer par utilisateur"
              hide-details="auto"
              clearable
              @input="getDocuments(1)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="$store.state.document.field.admin"
              label="Filtrer par validateur"
              outlined
              dense
              placeholder="Filtrer par validateur"
              hide-details="auto"
              clearable
              @input="getDocuments(1)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="$store.state.document.field.type"
              :items="$store.getters['document/documentTypes']"
              item-text="label"
              item-value="id"
              label="Rechercher par type"
              outlined
              dense
              placeholder="Rechercher par type"
              clearable
              hide-details="auto"
              persistent-hint
              single-line
              @input="getDocuments(1)"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="$store.state.document.field.status"
              :items="[{id: 0, label: 'En attente'},{id: 1, label: 'Validé'},{id: -1,label: 'Rejeté'}]"
              item-text="label"
              item-value="id"
              label="Rechercher par status"
              outlined
              dense
              placeholder="Rechercher par status"
              clearable
              hide-details="auto"
              persistent-hint
              single-line
              @input="getDocuments(1)"
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="$store.getters['document/documents']"
        :items-per-page="$store.getters['document/per_page']"
        :page.sync="$store.getters['document/current_page']"
        dense
        fixed-header
        height="500"
        hide-default-footer
        :loading="isDialogVisible"
        loading-text="Chargement..."
        @page-count="currentPage = $event"
      >
        <template #[`item.user`]="{item}">
          {{ item.user !== null ? (item.user.name !== null ? item.user.name : item.user.phone_number) : null }}
        </template>
        <template #[`item.admin`]="{item}">
          {{ item.admin !== null ? (item.admin.name !== null ? item.admin.name : item.admin.phone_number) : null }}
        </template>
        <template #[`item.status`]="{item}">
          <v-chip
            v-if="item.status == -1"
            small
            color="error"
          >
            {{ $t("Rejeté")}}
          </v-chip>
          <v-chip
            v-if="item.status == 0 || item.status == null || !item.status"
            small
          >
            {{ $t("En attente")}}
          </v-chip>
          <v-chip
            v-if="item.status == 1"
            small
            color="success"
          >
            {{ $t("Validé")}}
          </v-chip>
        </template>
        <template #[`item.type`]="{item}">
          <v-chip
            v-if="item.type === 'id_card'"
            small
            color="primary"
          >
            {{ $t("CNI") }}
          </v-chip>
          <v-chip
            v-else
            small
            color="secondary"
          >
            {{ $t("Permis") }}
          </v-chip>
        </template>

        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>
        <template #[`item.processed_at`]="{item}">
          {{ item.processed_at ?formattingDate(`${item.processed_at || ''}`):null }}
        </template>

        <template #[`item.image_1`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              color="primary"
              class="v-avatar-light-bg primary--text"
              size="32"
            >
              <v-img
                :alt="item.image_1"
                :src="item.image_1"
              ></v-img>
            </v-avatar>
          </div>
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <buttons-group
            :item="item"
            :edit="false"
            :remove="false"
            :lock="false"
            :status="false"
            @view="openItem(item)"
          />
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-text-field
              :value="$store.state.document.field.paginate"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getDocumentsPerPage"
            ></v-text-field>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getDocuments"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiSquareEditOutline, mdiDotsVertical, mdiDeleteOutline, mdiPencilOutline, mdiAccountLock, mdiLockOpen, mdiEyeOutline, mdiRefresh,
  mdiCashPlus, mdiCashMinus, mdiCashCheck,mdiLicense,mdiDocument
} from '@mdi/js'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import { ref, onMounted, watch } from '@vue/composition-api/dist/vue-composition-api'
import CityInput from '@core/components/autocomplete/CityInput'
import { formatDate } from '@core/date/dateFunctions'
import { useRouter } from '@core/utils'
import {intToCurrency, notify} from '@core/dynamicComponents/dynamicComponents'
import store from '@/store'
import CustomToolbar from "@core/components/toolbar/CustomToolbar";
import ButtonsGroup from "@core/components/buttons/ButtonsGroup";

export default {
  name: 'Document',
  components: {ButtonsGroup, CustomToolbar, CityInput, LoaderDialog },

  setup() {
    const date = ref(null)
    const menu = ref(false)
    const picker = ref(null)
    const menuref = ref(null)
    const message = ref(null)
    const itemsPerPage = ref(null)
    const currentPage = ref(null)
    const lastPage = ref(null)
    const error = ref(null)
    const messageDialog = ref('')
    const isDialogVisible = ref(false)
    const dialog = ref(false)
    const dialogDelete = ref(false)
    const isAlertVisible = ref(false)
    const { router } = useRouter()
    const rules = {
      svc_number: [true],
      reference: [true],
      nb_passagers: [true],
      transaction_id: [true],
      base_amount: [true],
      amount: [true],
      type: [true],
      op_api_id: [true],
    }

    const formatAmount = amount => intToCurrency(store.state.user.lang, {
      style: 'currency',
      currency: 'XAF',
      minimumFractionDigits: 0,
    }, amount)

    const opApisItems = ref([])

    const save = d => {
      menuref.value.save(d)
    }

    const formattingDate = date => formatDate(date)

    const deleteItemConfirm = () => {
      closeDelete()
    }

    const openItem = item => {
      router.push({ name: 'showuser', params: { id: item.user_id } })
    }

    const closeDelete = () => {
      dialogDelete.value = false
    }

    const getDocumentTypes = () => {
      store.dispatch('document/getdocumentTypes').catch(error => {
        console.log(error.response.data.message)
      })
    }

    const getOpApis = () => {
      store.dispatch('document/getOpApis').then(() => {
        store.getters['document/opApis'].forEach(elt => {
          opApisItems.value.push({ text: elt.operator.label, value: elt.id, image: elt.operator.logo_path })
        })
      }).catch(error => {
        console.log(error.response.data.message)
      })
    }

    const getDocuments = (page = 1) => {
      console.log('field',store.state.document)
      isDialogVisible.value = true
      store.dispatch('document/getDocumentsList', { page, field: store.state.document.field }).then(() => {
        currentPage.value = store.getters['document/current_page']
        lastPage.value = store.getters['document/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false

        /* Object.keys(this.rules).forEach(key => {
          this.rules[key] = [true]
        }) */
      }).catch(errorserver => {
        message.value = errorserver.response.data.message
        if (errorserver.response.data.errors !== undefined) {
          rules.svc_number = errorserver.response.data.errors.svc_number !== undefined ? errorserver.response.data.errors.svc_number : [true]
          rules.reference = errorserver.response.data.errors.reference !== undefined ? errorserver.response.data.errors.reference : [true]
          rules.nb_passagers = errorserver.response.data.errors.nb_passagers !== undefined ? errorserver.response.data.errors.nb_passagers : [true]
          rules.transaction_id = errorserver.response.data.errors.transaction_id !== undefined ? errorserver.response.data.errors.transaction_id : [true]
          rules.document_type_id = errorserver.response.data.errors.document_type_id !== undefined ? errorserver.response.data.errors.document_type_id : true
          rules.base_amount = errorserver.response.data.errors.base_amount !== undefined ? errorserver.response.data.errors.base_amount : true
          rules.amount = errorserver.response.data.errors.amount !== undefined ? errorserver.response.data.errors.amount : [true]
          rules.op_api_id = errorserver.response.data.errors.op_api_id !== undefined ? errorserver.response.data.errors.op_api_id : [true]
        }
        error.value = true
        isAlertVisible.value = true
      })
    }

    const refreshDocuments = (page = 1) => {
      isDialogVisible.value = true
      store.dispatch('document/refeshDocumentsList', { page, field: store.state.document.field }).then(() => {
        currentPage.value = store.getters['document/current_page']
        lastPage.value = store.getters['document/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false

        /* Object.keys(this.rules).forEach(key => {
          this.rules[key] = [true]
        }) */
      }).catch(error => {
        message.value = error.response.data.message
        if (error.response.data.errors !== undefined) {
          rules.svc_number = error.response.data.errors.svc_number !== undefined ? error.response.data.errors.svc_number : [true]
          rules.reference = error.response.data.errors.reference !== undefined ? error.response.data.errors.reference : [true]
          rules.nb_passagers = error.response.data.errors.nb_passagers !== undefined ? error.response.data.errors.nb_passagers : [true]
          rules.transaction_id = error.response.data.errors.transaction_id !== undefined ? error.response.data.errors.transaction_id : [true]
          rules.document_type_id = error.response.data.errors.document_type_id !== undefined ? error.response.data.errors.document_type_id : true
          rules.base_amount = error.response.data.errors.base_amount !== undefined ? error.response.data.errors.base_amount : true
          rules.amount = error.response.data.errors.amount !== undefined ? error.response.data.errors.amount : [true]
          rules.op_api_id = error.response.data.errors.op_api_id !== undefined ? error.response.data.errors.op_api_id : [true]
        }
        error.value = true
        isAlertVisible.value = true
      })
    }

    const filterItems = () => {
      setTimeout(() => {
        getDocuments()
      }, 1500)
    }

    const getDocumentsPerPage = per_page => {
      // eslint-disable-next-line camelcase
      if (per_page !== null) {
        isDialogVisible.value = true
        // eslint-disable-next-line camelcase
        store.state.document.field.paginate = per_page
        itemsPerPage.value = parseInt(per_page, 10)
        store.dispatch('document/getDocumentsList', {
          page: currentPage.value,
          field: store.state.document.field,
        }).then(() => {
          currentPage.value = store.getters['document/current_page']
          lastPage.value = store.getters['document/last_page']
          isDialogVisible.value = false
          isAlertVisible.value = false

          /* Object.keys(this.rules).forEach(key => {
            this.rules[key] = [true]
          }) */
        })
      }
    }

    const getStartOmsId = event => {
      store.state.document.field.base_amount = event ? event.toString() : null
      getDocuments()
    }

    const getEndOmsId = event => {
      store.state.document.field.amount = event ? event.toString() : null
      getDocuments()
    }

    const verifyStatus = item => {
      isDialogVisible.value = true
      store.dispatch('document/verifyDocumentStatus', item).then(response => {
        message.value = response.message
        isDialogVisible.value = false
        isAlertVisible.value = true
        notify(true, true, response.message)
        error.value = false
      }).catch(error => {
        console.log(error.response.data.message)
        message.value = error.response.data.message
        isDialogVisible.value = false
        isAlertVisible.value = true
        notify(true, true, error.response.data.message)
        error.value = true
      })
    }

    watch(() => store.state.user.lang, lang => {
      refreshDocuments(store.getters['document/current_page'])
    })

    onMounted(() => {
      getDocuments(store.getters['document/current_page'])
      getOpApis()
      getDocumentTypes()
    })

    return {
      formatAmount,
      getDocumentsPerPage,
      deleteItemConfirm,
      openItem,
      closeDelete,
      formattingDate,
      getStartOmsId,
      getEndOmsId,
      verifyStatus,
      getDocuments,
      save,
      opApisItems,
      date,
      menu,
      picker,
      menuref,
      message,
      currentPage,
      lastPage,
      error,
      messageDialog,
      isDialogVisible,
      dialog,
      dialogDelete,
      isAlertVisible,
      rules,
      filterItems,
      headers: [
        { text: 'REFERENCE', value: 'id' },
        { text: 'TYPE DE DOCUMENT', value: 'type' },
        { text: 'UTILISATEUR', value: 'user' },
        { text: 'STATUT', value: 'status' },
        { text: 'DATE DE CREATION', value: 'created_at' },
        { text: 'VALIDATEUR', value: 'admin' },
        { text: 'DATE DE TRAITEMENT', value: 'processed_at' },
        { text: 'ACTIONS', value: 'actions' },
      ],
      customHeader: [
        { text: 'REFERENCE', value: 'reference', class: 'sticky-header' },
      ],
      itemsPerPage: parseInt(store.state.document.field.paginate, 10),

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiEyeOutline,
        mdiRefresh,
        mdiCashMinus,
        mdiCashPlus,
        mdiCashCheck,
        mdiLicense,
        mdiDocument,
      },
    }
  },
}
</script>

<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 9998;
}
.v-data-table /deep/ .v-data-table__wrapper {
  //overflow: unset;
}
/*table > tbody > tr > td:nth-child(2),
table > thead > tr > th:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 9998;
  background: white;
}
table > thead > tr > th:nth-child(2) {
  z-index: 9998;
}*/
</style>
